/** @jsx jsx */
import { css } from '@emotion/core';
import { mq, color } from "../../constants/styleConstants";

export const headerStyle = {
  block: css`
    position: absolute;
    z-index: 10;
    width: 100%;
    svg {
      path, rect, circle, polygon {
        transition: 1.2s ease;
      }
    }
    a {
      &:hover {
        path, rect, circle, polygon {
          transition: 0.6s ease;
          fill: ${color.gold};
        }
      }
    }
  `,
  blockBlend: css`
    mix-blend-mode: difference;
    svg {
      path, rect, circle, polygon {
        fill: #fff;
      }
    }
    a {
      &:hover {
        path, rect, circle, polygon {
          fill: ${color.goldInvert};
        }
      }
    }
  `,
  toggleButton: css`
    position: fixed;
    top: 0.625rem;
    left: 0;
    display: block;
    z-index: 12;
    cursor: pointer;
    span {
      transition: all 0.6s ease;
    }
  `,
  toggleButtonOpened: css`
    span {
      &:nth-of-type(1) {
        transform: translate3d(0,0.4375rem,0) rotate(45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translate3d(0,-0.4375rem,0) rotate(-45deg);
      }
    }
  `,
  toggleBlend: css`
    mix-blend-mode: difference;
  `,
  toggleButtonInner: css`
    display: flex;
    align-items: center;
  `,
  toggleLines: css`
    width: 4rem;
    padding: 0.375rem 1rem;
    margin: 0;
    transform: scaleX(1);
    transform-origin: left;
    //transition: transform 1.2s ease;
    span {
      display: block;
      width: 100%;
      height: 0.125rem;
      background: #000;
      margin: 0.3125rem 0;
      //transition: background-color 1.2s ease;
      //backface-visibility: hidden;
    }
    &:hover {
      span {
        background: ${color.gold};
        //transition: background-color 0.6s ease;
      }
      @media (hover:none) {
        span {
          background: #000;
        }
      }
    }
  `,
  toggleLinesBlend: css`
    span {
      background: #fff;
    }
    &:hover {
      span {
        background: ${color.goldInvert};
      }
      @media (hover:none) {
        span {
          background: #fff;
        }
      }
    }
  `,
  toggleLinesOpened: css`
    pointer-events: none;
  `,
  logoBlock: css`
    width: 6.5rem;
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    display: block;
    img, svg {
      display: block;
      width: 100%;
      height: auto;
    }
    ${mq('medium')} {
      width: 11.375rem;
    }
  `,
  iconBlock: css`
    position: fixed;
    top: 0;
    right: 0;
  `,
  icon: css`
    width: 3.5rem;
    height: 3.5rem;
    padding: 1rem;
    display: block;
    svg {
      width: 100%;
      height: auto;
    }
  `,
}
