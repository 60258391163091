/** @jsx jsx */
import { css } from '@emotion/core';
import { color, mq } from "../../constants/styleConstants";

export const loadingCoverStyle = {
  block: css`
    position: fixed;
    background: ${color.bgGray};
    //background: ${color.gold};
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 100;
  `,
  image: css`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 36vw;
    transform: translate(-50%, -50%);
    backface-visibility: hidden;
    svg {
        width: 100%;
    }
    ${mq('small')} {
      width: 24vw;
    }
  `,
}
