import React, { useContext, useRef } from "react";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";
import { modalStyle } from "./modalStyle";
import { disableScroll, enableScroll } from "../../utilities/scrollUtility";
import GlobalContext from "../../context/globalContext";

const Modal = ({ open, children, styles = {}, exitDelay = 0 }) => {
  const { blockStyle = {}, innerStyle = {} } = styles;
  const enterDuration = 600;
  const exitDuration = 600 + (exitDelay * 1000);
  const innerRef = useRef(null);
  const global = useContext(GlobalContext);

  const doOpenProcess = () => {
    const html = document.documentElement;
    html.style.paddingRight = `${global.scrollBarWidth}px`;
    document.querySelector(".page-header").style.width =
      `calc(100% - ${global.scrollBarWidth}px)`;
  }
  const doCloseProcess = () => {
    const html = document.documentElement;
    html.style.paddingRight = null;
    document.querySelector(".page-header").style.width = "100%";
  }

  return (
    <Transition
      in={open}
      timeout={{
        appear: enterDuration,
        enter: enterDuration,
        exit: exitDuration,
      }}
      mountOnEnter
      unmountOnExit={true}
      addEndListener={(node, done) => {
        const modalRef = { current: node };

        if (open) {
          disableScroll(innerRef.current);
          doOpenProcess();
          gsap
            .to(modalRef.current,
              {
                opacity: 1,
                duration: enterDuration / 1000,
                ease: "power3.out",
              }
            )
            .then(done);
        } else {
          // timeline.reverse().then(done).then(() => timeline.clear());
          // or more subtle reverse function
          gsap
            .to(modalRef.current,
              {
                opacity: 0,
                duration: enterDuration / 1000,
                ease: "power1.out",
                delay: exitDelay,
                onComplete: () => {
                  enableScroll();
                  doCloseProcess();
                },
              }
            )
            .then(done);
        }
      }}
    >
      <div css={[modalStyle.block, blockStyle]}>
        <div css={[modalStyle.inner, innerStyle]} ref={innerRef}>
          {children}
        </div>
      </div>
    </Transition>
  );
}

export default Modal
