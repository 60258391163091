import React, { useContext, useEffect, useRef, useState } from "react";
import { loadingCoverStyle } from "./loadingCoverStyle";
import { disableScroll, enableScroll } from "../../utilities/scrollUtility";
import { gsap } from "gsap";
import { Transition } from "react-transition-group";
import LogoImg from "../../images/logo.svg";
import { duration } from "../../constants/componentConstants";
import GlobalContext from "../../context/globalContext";

const LoadingCover = () => {
  const [open, setOpen] = useState(true);
  const logoRef = useRef(null);
  const global = useContext(GlobalContext);

  const enterDuration = 500;
  const exitDuration = duration * 2 * 1000;

  useEffect(() => {
    const checkFontLoaded = () => {
      if (!document.documentElement.classList.contains("wf-active")) {
        setTimeout(() => {
          checkFontLoaded();
        }, 100);
      } else {
        setTimeout(() => {
          setOpen(false);
          setTimeout(() => {
            global.setIsLoading(false);
          }, exitDuration);
        }, 1000);
      }
    }
    checkFontLoaded();
    disableScroll();
    return () => {
      enableScroll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  return (
    <Transition
      in={open}
      timeout={{
        appear: enterDuration,
        enter: enterDuration,
        exit: exitDuration,
      }}
      mountOnEnter
      unmountOnExit={true}
      addEndListener={(node, done) => {
        const modalRef = { current: node };

        if (open) {
          disableScroll();
          gsap
            .to(modalRef.current,
              {
                translateX: 0,
                duration: enterDuration / 1000,
                ease: "power2.in",
              }
            )
            .then(done);
        } else {
          const tl = gsap.timeline();
          tl
            .to(logoRef.current,
              {
                opacity: 0,
                duration: exitDuration / 1000 / 2,
                ease: "power2.out",
              }
            )
            .to(modalRef.current,
              {
                translateX: "100%",
                duration: exitDuration / 1000 / 2,
                ease: "power2.out",
                onComplete: () => {
                  enableScroll();
                },
              }
            )
            .then(done);
        }
      }}
    >
      <div css={loadingCoverStyle.block}>
        <div css={loadingCoverStyle.image} ref={logoRef}>
          <LogoImg title="Weaver's QUEST" />
        </div>
      </div>
    </Transition>
  );
}

export default LoadingCover
