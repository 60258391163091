import React from "react"
import { sideMenuStyle } from "./sideMenuStyle";
import Modal from "../Modal/modal";
import SideMenuContent from "./sideMenuContent";

const SideMenu = ({
                    open,
                    entryAnimation,
                    exitAnimation,
                    onClose,
                  }) => {
  const modalStyles = {
    blockStyle: sideMenuStyle.modalBlock,
  }
  return (
    <Modal open={open} styles={modalStyles} exitDelay={0.8}>
      <SideMenuContent
        open={open}
        entryAnimation={entryAnimation}
        exitAnimation={exitAnimation}
        onClose={onClose}
      />
    </Modal>
  );
}

export default SideMenu
