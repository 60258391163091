import React, { useContext, useEffect, useState } from "react"
import { headerStyle } from "./headerStyle";
import LogoImg from "../../images/logo.svg";
import IconInstagram from "../../images/icons/icon_instagram.svg";
import TransitionLink from "gatsby-plugin-transition-link";
import SideMenu from "../SideMenu/sideMenu";
import GlobalContext from "../../context/globalContext";

const Header = ({ hideHeaderLogo, entryAnimation, exitAnimation }) => {
  const [isSideMenuShow, setIsSideMenuShow] = useState(false);
  const global = useContext(GlobalContext);

  useEffect(() => {
    // Create the measurement node
    const scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    document.body.appendChild(scrollDiv);

    // Get the scrollbar width
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    // console.warn(scrollbarWidth); // Mac:  15
    global.setScrollBarWidth(scrollbarWidth);

    // Delete the DIV
    document.body.removeChild(scrollDiv);
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  const onClickToggle = () => {
    if (!isSideMenuShow) {
      setIsSideMenuShow(true);
      // setIsButtonHide(true);
    } else {
      setIsSideMenuShow(false);
    }
  }
  const onCloseSideMenu = () => {
    setIsSideMenuShow(false);
    // setTimeout(() => {
    //   setIsButtonHide(false);
    // }, 1400);
  }

  const blockStyle = hideHeaderLogo
    ? [headerStyle.block, headerStyle.blockBlend]
    : headerStyle.block;
  const toggleLinesStyle = hideHeaderLogo
    ? [headerStyle.toggleLines, headerStyle.toggleLinesBlend]
    : [headerStyle.toggleLines];
  const toggleButtonStyle = isSideMenuShow
    ? [headerStyle.toggleButton, headerStyle.toggleButtonOpened]
    : [headerStyle.toggleButton];
  if (hideHeaderLogo) {
    toggleButtonStyle.push(headerStyle.toggleBlend);
  }

  return (
    <>
      <header css={blockStyle} className="page-header">
        {
          !hideHeaderLogo && (
            <TransitionLink
              to="/"
              exit={{
                trigger: exitAnimation,
                length: 1.5
              }}
              entry={{
                trigger: entryAnimation,
                delay: .5,
                length: 1
              }}
              css={headerStyle.logoBlock}
            >
              <LogoImg title="Weaver's QUEST" />
            </TransitionLink>
          )
        }
        <div css={headerStyle.iconBlock}>
          <a
            css={headerStyle.icon}
            href="https://www.instagram.com/kawashimaselkon_textiles/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconInstagram title="Instagram" />
          </a>
        </div>
      </header>
      <button css={toggleButtonStyle} onClick={onClickToggle}>
        <div css={headerStyle.toggleButtonInner}>
          <div css={toggleLinesStyle}>
            <span className="toggle-line" />
            <span className="toggle-line" />
            <span className="toggle-line" />
          </div>
        </div>
      </button>
      <SideMenu
        open={isSideMenuShow}
        entryAnimation={entryAnimation}
        exitAnimation={exitAnimation}
        onClose={onCloseSideMenu}
      />
    </>
  );
}

export default Header
