const preventEvent = function( e ){
  e.preventDefault();
  e.stopPropagation();
}

export function disableScroll(element) {
  const html = document.documentElement;
  const body = document.body;
  html.style.overflow = 'hidden';
  body.style.overflow = 'hidden';
  if (!element || element.scrollHeight <= window.innerHeight) {
    window.addEventListener('touchmove', preventEvent, { passive: false });
  }
}

export function enableScroll() {
  const html = document.documentElement;
  const body = document.body;
  window.removeEventListener( 'touchmove' , preventEvent, { passive: true } );
  html.style.overflow = null;
  body.style.overflow = null;
}

export const waitForFinalEvent = (function () {
  const timers = {};
  return function (callback, ms, uniqueId) {
    if (!uniqueId) { uniqueId = "Don't call this twice without a uniqueId"; }
    if (timers[uniqueId]) { clearTimeout (timers[uniqueId]); }
    timers[uniqueId] = setTimeout(callback, ms);
  };
})();
