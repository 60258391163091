import React, { useEffect, useRef } from "react"
import { sideMenuStyle } from "./sideMenuStyle";
import LogoImg from "../../images/logo.svg";
import TransitionLink from "gatsby-plugin-transition-link";
import TitleVCenter from "../../images/title_v_black_center.svg";
import LogoKawashimaImg from "../../images/logo_kawashimaorimono.svg";
import { duration } from "../../constants/componentConstants";
import { gsap } from "gsap";

const SideMenuContent = ({
                    open,
                    entryAnimation,
                    exitAnimation,
                    onClose,
                  }) => {
  const exitSetting = {
    trigger: exitAnimation,
    length: duration * 3
  };
  const entrySetting = {
    trigger: entryAnimation,
    delay: duration,
    length: duration * 2
  };

  const logoRef = useRef(null);
  const menu01Ref = useRef(null);
  const menu02Ref = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {
    const duration = 1;
    const durationClose = 0.6;
    const delay = 0.4;
    const yDelta = 40;
    const yDeltaClose = 30;
    const easing = "power3.out";
    const easingClose = "power1.in";
    if (open) {
      gsap.set(logoRef.current, {y: yDelta, opacity: 0});
      gsap.set(menu01Ref.current, {y: yDelta, opacity: 0});
      gsap.set(menu02Ref.current, {y: yDelta, opacity: 0});
      gsap.set(footerRef.current, {y: yDelta, opacity: 0});

      gsap.to(logoRef.current, {
          y: 0, opacity: 1, duration: duration, delay: delay, ease: easing,
        }
      );
      gsap.to(menu01Ref.current, {
          y: 0, opacity: 1, duration: duration, delay: (delay + 0.1), ease: easing,
        }
      );
      gsap.to(menu02Ref.current, {
          y: 0, opacity: 1, duration: duration, delay: (delay + 0.15), ease: easing,
        }
      );
      gsap.to(footerRef.current, {
          y: 0, opacity: 1, duration: duration, delay: (delay + 0.3), ease: easing,
        }
      );
    } else {
      gsap.to(logoRef.current, {
          y: yDeltaClose, opacity: 0, duration: durationClose, delay: 0.12, ease: easingClose,
        }
      );
      gsap.to(menu01Ref.current, {
          y: yDeltaClose, opacity: 0, duration: durationClose, delay: 0.08, ease: easingClose,
        }
      );
      gsap.to(menu02Ref.current, {
          y: yDeltaClose, opacity: 0, duration: durationClose, delay: 0.04, ease: easingClose,
        }
      );
      gsap.to(footerRef.current, {
          y: yDeltaClose, opacity: 0, duration: durationClose, ease: easingClose,
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [open]);

  return (
    <div css={sideMenuStyle.block}>
      <div css={sideMenuStyle.inner}>
        <div ref={logoRef}>
          <TransitionLink
            to="/"
            exit={exitSetting}
            entry={entrySetting}
            onClick={onClose}
          >
            <div css={sideMenuStyle.logoBlock}>
              <LogoImg title="Weaver's QUEST" />
            </div>
          </TransitionLink>
          <div css={sideMenuStyle.siteName}>
            <TitleVCenter title="織物屋の試み 展 其の二 ファッションデザイナー編" />
          </div>
        </div>
        <div css={sideMenuStyle.menuBlock} ref={menu01Ref}>
          <div>Designer</div>
          <ul css={sideMenuStyle.menuList}>
            <li>
              <TransitionLink
                to="/designer-mihara-yasuhiro/"
                exit={exitSetting}
                entry={entrySetting}
                onClick={onClose}
              >
                - MIHARA YASUHIRO
              </TransitionLink>
            </li>
            <li>
              <TransitionLink
                to="/designer-christelle-kocher/"
                exit={exitSetting}
                entry={entrySetting}
                onClick={onClose}
              >
                - CHRISTELLE KOCHER
              </TransitionLink>
            </li>
            <li>
              <TransitionLink
                to="/designer-rok-hwang/"
                exit={exitSetting}
                entry={entrySetting}
                onClick={onClose}
              >
                - ROK HWANG
              </TransitionLink>
            </li>
          </ul>
        </div>
        <div css={sideMenuStyle.menuBlock} ref={menu02Ref}>
          <ul css={sideMenuStyle.menuList}>
            <li>
              <TransitionLink
                to="/about/"
                exit={exitSetting}
                entry={entrySetting}
                onClick={onClose}
              >
                ABOUT US
              </TransitionLink>
            </li>
            <li>
              <TransitionLink
                to="/voice/"
                exit={exitSetting}
                entry={entrySetting}
                onClick={onClose}
              >
                VOICE
              </TransitionLink>
            </li>
            <li>
              <TransitionLink
                to="/photo/"
                exit={exitSetting}
                entry={entrySetting}
                onClick={onClose}
              >
                PHOTO GALLERY
              </TransitionLink>
            </li>
          </ul>
        </div>
        <div css={sideMenuStyle.footer} ref={footerRef}>
          <a href="https://www.kawashimaselkon.co.jp/" target="_blank" rel="noopener noreferrer">
            <LogoKawashimaImg title="川島織物" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default React.memo(SideMenuContent);
