/** @jsx jsx */
import { css } from '@emotion/core'

export const bp = {
  small: 481,
  small2: 640,
  medium: 768,
  large: 1100,
  xlarge: 1240,
};

export const mq = n => {
  const bpArray = Object.keys(bp).map(key => [key, bp[key]]);

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (min-width: ${size}px)`];
    return acc;
  }, []);

  return result;
};

export const color = {
  gold: "#B28146",
  goldInvert: "#4D7EB9",
  bgGray: "#ededed",
  pink: "#ff7d7d",
};

export const enFont01 = {
  regular: css`
    font-family: 'apercuregular';
    font-weight: 400;
  `,
  bold: css`
    font-family: 'apercubold';
    font-weight: 400;
  `,
  light: css`
    font-family: 'aperculight';
    font-weight: 400;
  `,
}

export const jpFont01 = {
  light: css`
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 200;
  `,
  regular: css`
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 400;
  `,
  medium: css`
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 500;
  `,
  bold: css`
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
  `,
}

