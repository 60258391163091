/** @jsx jsx */
import { css } from '@emotion/core';
import { color, enFont01, jpFont01, mq } from "../../constants/styleConstants";

export const footerStyle = {
  block: css`
    padding: 4rem 1.25rem 2.5rem;
    background: ${color.bgGray};
    position: relative;
    margin-top: 4rem;
    z-index: 1;
    ${mq('medium')} {
      padding: 2.5rem 2.5rem 5rem;
      margin-top: 8rem;
    }
  `,
  introBlock: css`
  `,
  introTitle: css`
    width: 14rem;
    svg {
      width: 100%;
      height: auto;
    }
    ${mq('small')} {
      width: 18rem;
    }
  `,
  introInfo: css`
    ${enFont01.bold};
    font-size: 0.875rem;
    margin-top: 1rem;
  `,
  infoBlock: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 40rem;
    margin-top: 2.5rem;
    ${jpFont01.bold};
    font-size: 0.75rem;
    & > div {
      width: 48%;
      margin-bottom: 2.5rem;
    }
    ${mq('medium')} {
      & > div {
        width: 30%;
      }
    }
  `,
  linkKawashima: css`
    width: 2.75rem;
    position: absolute;
    top: 1.5rem;
    right: 1.25rem;
    ${mq('medium')} {
      width: 3.125rem;
      top: 2.5rem;
      right: 2.5rem;
    }
    a {
      display: block;
      img, svg {
        width: 100%;
        height: 100%;
      }
    }
  `,
  copyright: css`
    ${enFont01.bold};
    font-size: 0.6875rem;
    text-align: right;
  `,
}
