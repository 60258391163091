import React from "react"
import { footerStyle } from "./footerStyle";
import LogoKawashimaImg from "../../images/logo_kawashimaorimono.svg";
import TitleVBlack from "../../images/title_v_black.svg";

const Footer = () => (
  <footer css={footerStyle.block}>
    <div css={footerStyle.introBlock}>
      <h2 css={footerStyle.introTitle}>
        <TitleVBlack title="織物屋の試み 展 其の二 ファッションデザイナー編" />
      </h2>
      <div css={footerStyle.introInfo}>
        2020.11.18(Wed) - 11.29(Sun)
        <br />
        Venue: FRITZ HANSEN STORE AOYAMA
      </div>
    </div>
    <div css={footerStyle.infoBlock}>
      <div>
        張り地デザイン<br />
        青木明(AKIKOAOKI)<br />
        井野将之(doublet)<br />
        堀内太郎(TARO HORIUCHI)<br />
        <br />
        協力 フリッツ・ハンセン
      </div>
      <div>
        張り地デザイン<br />
        青木明(AKIKOAOKI)<br />
        井野将之(doublet)<br />
        堀内太郎(TARO HORIUCHI)<br />
        <br />
        協力 フリッツ・ハンセン
      </div>
      <div>
        張り地デザイン<br />
        青木明(AKIKOAOKI)<br />
        井野将之(doublet)<br />
        堀内太郎(TARO HORIUCHI)<br />
        <br />
        協力 フリッツ・ハンセン
      </div>
    </div>
    <div css={footerStyle.linkKawashima}>
      <a href="https://www.kawashimaselkon.co.jp/" target="_blank" rel="noopener noreferrer">
        <LogoKawashimaImg title="川島織物" />
      </a>
    </div>
    <div css={footerStyle.copyright}>
      © Kawashima Selkon Textiles Co.,LTD. All Rights Reserved.
    </div>
  </footer>
)

export default Footer
