/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import { Global } from '@emotion/core';
import { globalStyle } from "./globalStyle";
import { css } from '@emotion/core';
import GlobalContext from '../context/globalContext'
import Header from "./Header/header"
// import "./layout.css"
import "./common.css"
import { Helmet } from "react-helmet";
import Footer from "./Footer/footer";
import LoadingCover from "./LoadingCover/loadingCover";

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  const bgStyle = css`
    background: #fff;
  `;

  return (
    <GlobalContext.Consumer>
      {global => (
        <>
          <Global styles={globalStyle} />
          <Helmet>
          <script>
          {`
            (function(d) {
              var config = {
              kitId: 'tov7ehr',
              scriptTimeout: 3000,
              async: true
            },
              h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\\bwf-loading\\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
            })(document);
            `}
          </script>
          </Helmet>
          <LoadingCover />
          <Header
            hideHeaderLogo={global.hideHeaderLogo}
            entryAnimation={global.entryAnimation}
            exitAnimation={global.exitAnimation}
          />
          <div css={bgStyle}>
            <main>{children}</main>
            <Footer />
          </div>
        </>
        )}
    </GlobalContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
