/** @jsx jsx */
import { css } from '@emotion/core';

export const modalStyle = {
  block: css`
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
  `,
  inner: css`
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  `,
}
