import React from "react"

// Note: I'm not passing a defaultValue to createContext
// as the whole app is going to be wrapped into the GlobalProvider using the browser API wrapRootElement

const defaultState = {
  hideHeaderLogo: true,
  isLoading: true,
  scrollBarWidth: 0,
  entryAnimation: () => {},
  exitAnimation: () => {},
  setHideHeaderLogo: () => {},
  setIsLoading: () => {},
  setEntryAnimation: () => {},
  setExitAnimation: () => {},
  setScrollBarWidth: () => {},
}
const GlobalContext = React.createContext(defaultState)

class GlobalProvider extends React.Component {
  state = {
    hideHeaderLogo: true,
    isLoading: true,
  }

  setHideHeaderLogo = (hideHeaderLogo) => {
    this.setState({ hideHeaderLogo })
  }
  setIsLoading = (isLoading) => {
    this.setState({ isLoading })
  }
  setEntryAnimation = (entryAnimation) => {
    this.setState({ entryAnimation })
  }
  setExitAnimation = (exitAnimation) => {
    this.setState({ exitAnimation })
  }
  setScrollBarWidth = (scrollBarWidth) => {
    this.setState({ scrollBarWidth })
  }

  render() {
    const { children } = this.props
    const {
      hideHeaderLogo,
      isLoading,
      entryAnimation,
      exitAnimation,
      scrollBarWidth,
    } = this.state
    return (
      <GlobalContext.Provider
        value={{
          hideHeaderLogo,
          isLoading,
          entryAnimation,
          exitAnimation,
          scrollBarWidth,
          setHideHeaderLogo: this.setHideHeaderLogo,
          setIsLoading: this.setIsLoading,
          setEntryAnimation: this.setEntryAnimation,
          setExitAnimation: this.setExitAnimation,
          setScrollBarWidth: this.setScrollBarWidth,
        }}
      >
        {children}
      </GlobalContext.Provider>
    )
  }
}
export default GlobalContext
export { GlobalProvider }
