// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-designer-christelle-kocher-js": () => import("./../../../src/pages/designer-christelle-kocher.js" /* webpackChunkName: "component---src-pages-designer-christelle-kocher-js" */),
  "component---src-pages-designer-mihara-yasuhiro-js": () => import("./../../../src/pages/designer-mihara-yasuhiro.js" /* webpackChunkName: "component---src-pages-designer-mihara-yasuhiro-js" */),
  "component---src-pages-designer-rok-hwang-js": () => import("./../../../src/pages/designer-rok-hwang.js" /* webpackChunkName: "component---src-pages-designer-rok-hwang-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photo-js": () => import("./../../../src/pages/photo.js" /* webpackChunkName: "component---src-pages-photo-js" */),
  "component---src-pages-voice-js": () => import("./../../../src/pages/voice.js" /* webpackChunkName: "component---src-pages-voice-js" */)
}

