/** @jsx jsx */
import { css } from '@emotion/core';
import { enFont01, jpFont01, mq } from "../../constants/styleConstants";

export const sideMenuStyle = {
  modalBlock: css`
    background: rgba(255,255,255,0.9);
    z-index: 11;
  `,
  block: css`
    z-index: 100;
    width: 100%;
    //height: 100%;
    //overflow: auto;
    position: relative;
  `,
  inner: css`
    max-width: 24rem;
    margin: 0 auto;
    padding: 3rem 0;
    text-align: center;
  `,
  logoBlock: css`
    width: 7.5rem;
    display: block;
    margin: 2rem auto;
    img, svg {
      display: block;
      width: 100%;
      height: auto;
    }
    ${mq('medium')} {
      width: 12.5rem;
    }
  `,
  siteName: css`
    width: 10rem;
    margin: 0 auto;
    svg {
      width: 100%;
      height: auto;
    }
    ${mq('medium')} {
       width: 14rem;
    }
  `,
  menuBlock: css`
    margin-top: 3rem;
    ${enFont01.bold};
    font-size: 1.25rem;
    ${mq('medium')} {
      font-size: 1.625rem;
    }
  `,
  menuList: css`
    margin-top: 0.5rem;
    line-height: 1.4;
    a {
      display: block;
      padding: 0.25rem 0.5rem;
    }
  `,
  footer: css`
    margin-top: 6rem;
  `,
  close: css`
    display: block;
    position: absolute;
    z-index: 100;
    top: 0.375rem;
    left: 0.5rem;
    cursor: pointer;
    padding: 0;
    width: 3rem;
    height: 3rem;
    span {
      width: 2rem;
      height: 0.125rem;
      position: absolute;
      top: 50%;
      left: 50%;
      background: #000;
      &:first-of-type{
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:last-of-type{
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  `,
}
